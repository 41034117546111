import React, {FunctionComponent, useEffect} from 'react';
import {ColumnLayout, Container, Box, Button} from "aws-northstar";
import Stack from "aws-northstar/layouts/Stack";
import './styles.css';
import {useOktaAuth} from "@okta/okta-react";
import {IUserInfo} from "../../interfaces";
import {useDispatch} from "react-redux";
import sso from "./sso.png";
import Link from 'aws-northstar/components/Link';


import {
    storeUserInfoAction
} from "../../redux/actions";
import ApiHandler from "../../common/api";
import {useHistory} from "react-router-dom";

const Homepage: FunctionComponent = () => {
    return <Stack>
        <HomepageContent/>
    </Stack>
}

// The content in the main content area of the App layout
export function HomepageContent() {

    const [request, setRequest] = React.useState(false);
    const [review, setReview] = React.useState(false);
    const [audit, setAudit] = React.useState(false);

    const {oktaAuth} = useOktaAuth();

    const dispatch = useDispatch();
    const history = useHistory();

    function createAccountMap(groups: string[]) {
        let accountMap = new Map();
        for (var group of groups) {
            if (group === process.env.REACT_APP_REVIEWER_ROLE) {
                setReview(true);
                ApiHandler.reviewer = true;
            } else if (group === process.env.REACT_APP_AUDITOR_ROLE) {
                setAudit(true);
                ApiHandler.auditor = true;
            } else {
                setRequest(true);
                ApiHandler.requester = true;
            }
        }
        return accountMap;
    }

    const login = async () => {
        if (oktaAuth.isLoginRedirect()) {
            await oktaAuth.handleLoginRedirect();
        } else if (!await oktaAuth.isAuthenticated()) {
            // Start the browser based oidc flow, then parse tokens from the redirect callback url
            oktaAuth.signInWithRedirect();
        }
    }

    const secinfo = async () => {

        const userInfo: IUserInfo = {
            token: "",
            user: "",

            requester: false,
            reviewer: false,
            auditor: false,

            accountMap: new Map([])
        }

        const claims = await oktaAuth.getUser();
        userInfo.user = claims.email ? claims.email : "";
        userInfo.accountMap = createAccountMap(claims.groups);

        const tokenManager = oktaAuth.tokenManager;
        const accessToken = await tokenManager.get('accessToken');
        const idToken = await tokenManager.get('idToken');
        if ("accessToken" in accessToken && "idToken" in idToken) {
            const authorization_value1 = 'Bearer '.concat(accessToken.accessToken ? accessToken.accessToken : "");
            const authorization_value2 = authorization_value1.concat(' ');
            const authorization_value3 = authorization_value2.concat(idToken.idToken ? idToken.idToken : "");

            userInfo.token = authorization_value3;
        }

        userInfo.requester = request;
        userInfo.reviewer = review;
        userInfo.auditor = audit;
        dispatch(storeUserInfoAction(userInfo));
    }

    const onOpenClick = () => {
        history.push(getLink());
    }

    const getLink = () => {

        if (request) {
            return "/Request-dashboard";
        } else if (review) {
            return "/Review-dashboard";
        } else if (audit) {
            return "/Audit-dashboard"
        } else {
            return "/"
        }
    }

    useEffect(() => {
        login().then(r => {
        });
        secinfo().then(r => {
        });
    });

    return (
        <div>
            <div className="awsui-grid awsui-util-p-s">
                <div className="awsui-util-pt-xxl awsui-row">
                    <div
                        className="custom-home-main-content-area col-xxs-10 offset-xxs-1 col-s-6 col-l-5 offset-l-2 col-xl-6">

                        <Container headingVariant='h4'
                                   title="Security & Control">
                            <div className="awsui-util-container back_ground_white text_black border_black">
                                <h1 className="awsui-text-large">
                                    <strong>&nbsp;&nbsp;Privileged Access Management</strong>
                                </h1>
                                <div><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Allow users to request
                                    temporary elevated access to your AWS environment</strong></div>
                                <div>
                                    <br/>
                                </div>
                                <Box>
                                    &nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary" onClick={onOpenClick}>Open
                                    dashboard</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                </Box>
                                {/* <div className="awsui-util-container-header">
                                    <h2>How it works</h2>
                                </div> */}
                            </div>
                        </Container>

                        <Container headingVariant='h4'
                                   title="How it works">
                            <div className="awsui-util-container">

                                <div>
                                    <ColumnLayout>

                                        <div data-awsui-column-layout-root={true}>
                                            <ul>
                                                <li>
                                                    Use this tool to get privileged access to AWS environment.
                                                </li>


                                                <li>
                                                    Users can request temporary elevated access to your AWS environment
                                                    if they are eligible to do so, based on their group memberships in
                                                    the Okta.
                                                </li>

                                                <li>
                                                    The tool infers that a user is eligible to request temporary
                                                    elevated access to IAM role «role» in AWS account ID «account» if
                                                    they belong to a group that has access to AFT-PrdReadMayEscalate permission set.
                                                    <ul>
                                                        <li>
                                                            <Link href="https://d-9667112994.awsapps.com/start#/">AWS SSO New Capital</Link>
                                                        </li>
                                                        <li>
                                                            This tool uses Okta as idP and checks whether your Okta group is authorized to
                                                            access AWS environment through AFT-PrdReadMayEscalate.

                                                            <img src={sso} width="50%" height="50%" alt="sso"/>
                                                        </li>

                                                        <li>
                                                            If a user does not have access to AFT-PrdReadMayEscalate for an AWS account, then they
                                                            will not be able to request for PAM.

                                                            <div>
                                                               Could not create the request: Error: You are not authorized to create PAM request for the account
                                                            </div>

                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                                                    While raising a request, a user is prompted to enter following information additional
                                                    information
                                                    <ul>
                                                        <li>
                                                            Justification
                                                        </li>
                                                        <li>
                                                            Account ID
                                                             <ul>
                                                                <li>
                                                                    <Link href="https://accounts.aws.sphdigital.sg/account-list.html">List of AWS accounts</Link>

                                                                </li>
                                                             </ul>
                                                        </li>
                                                        <li>
                                                            Role : Currently we support following roles.
                                                             <div>
                                                               Details of what each role can do can be seen 
                                                               <Link href="https://github.com/SPHTech-Platform/terraform-aws-aft-security/blob/main/iam.tf">here</Link>
                                                             </div>


                                                            <ul>
                                                                <li>
                                                                    PAM-Admin : Gives you full administrator access to the account
                                                                </li>
                                                                <li>
                                                                    PAM-PowerUser : Gives you administrator access but without IAM access
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            Duration
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                                                    Once raised, a request is evaluated by Department HOD. And it can be either approved or rejected.
                                                    Approvers will be notified via email once the request is raised.
                                                </li>

                                                <li>
                                                    Users are notified when their requests(via email) are approved or rejected
                                                </li>

                                                <li>
                                                    A user can log in and see their previous requests, including the
                                                    approval status of each request they have raised
                                                </li>

                                                <li>
                                                    From the time when a user's request is approved to when the
                                                    requested duration ends:
                                                    <ul>
                                                        <li>The user can click the "Access console" button next to that
                                                            request, to invoke a session in the AWS Management Console
                                                            using the approved IAM role and AWS account
                                                        </li>
                                                        <li>The user can also click the "CLI" button next to that
                                                            request to obtain temporary credentials which they can use
                                                            with the AWS CLI, for the same role and account
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                                                    Each session lasts 1 hour
                                                </li>

                                                <li>
                                                    A user can invoke as many sessions as they need to, for the duration
                                                    of their approved request
                                                </li>

                                                <li>
                                                    When the elevated access period ends, the user can no longer invoke
                                                    sessions
                                                    <ul>
                                                        <li>If they need further access they must raise another
                                                            request
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li>
                                                    Users can raise multiple concurrent requests for different
                                                    role-account combinations, as long as they are eligible
                                                </li>
                                            </ul>

                                        </div>

                                    </ColumnLayout>
                                </div>
                            </div>
                        </Container>

                    </div>

                </div>
            </div>
        </div>
    );
}


export default Homepage;

