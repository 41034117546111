import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';
import { Typography, InputProps } from '@material-ui/core';
import { makeStyles, FormField, Input } from 'aws-northstar';
import { FormFieldProps } from 'aws-northstar/components/FormField';
import { getErrorText } from 'aws-northstar/components/FormRenderer/utils/getErrorText';
import useUniqueId from 'aws-northstar/hooks/useUniqueId';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const useStyles = makeStyles((theme) => ({
    formFieldControls: {
        marginTop: '5px',
    },
    warningText: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.warning.main,
    }
  }));

  const WarningText: FunctionComponent<Partial<FormFieldProps>> = ({errorText}) => {
    const classes = useStyles();
    return (
      <Typography variant="subtitle1" component="div" className={clsx(classes.warningText, classes.formFieldControls)}>
          <ReportProblemOutlinedIcon fontSize="small" />
          <span>{errorText}</span>
      </Typography>
    );
  };

  /**
   * @see aws-northstar/packages/legacy/src/components/FormRenderer/components/TextField 
   * @see https://www.data-driven-forms.org/schema/validator-warnings
   * 
   * This component is a near copy of the aws-northstar's TextField component. This component
   * performs the same functionality, but adds the ability to use data-driven-forms validator
   * warnings. To use this component in your schema...
   * ```js
   *    {
   *        component: componentTypes.CUSTOM, // use the custom component type
   *         CustomComponent: CustomTextField, // pass the custom component to CustomComponent
   *         useWarnings: true, // enable "useWarnings" for this component (this is from data-driven-forms)
   *         // ...
   *         validate: [
   *             // ...
   *             {
   *                 // ...
   *                 warning: true, // inside your validation rule, indicate that this rule is a warning.
   *                 message: 'your warning message' // define your warning message.
   *             },
   *         ]
   *     }
   * ```
   */
  const CustomTextField: FunctionComponent<UseFieldApiConfig> = (props) => {
    const { 
      label,
      description,
      helperText,
      isRequired,
      isDisabled,
      isReadOnly,
      placeholder,
      input,
      validateOnMount,
      stretch,
      showError,
      secondaryControl,
      meta: { error, submitFailed, warning },
      ...rest
     } = props;
    const controlId = useUniqueId(input.name)
    const errorText = getErrorText(validateOnMount, submitFailed, showError, error)
  
    return (
      <FormField
        controlId={controlId}
        label={label}
        description={description}
        hintText={helperText}
        errorText={errorText}
        stretch={stretch}
        secondaryControl={secondaryControl}
      >
        <Input 
          {...rest}
          {...input}
          type={input.type as InputProps['type']}
          placeholder={placeholder}
          controlId={controlId}
          disabled={isDisabled}
          required={isRequired}
          readonly={isReadOnly}
          invalid={!!errorText}
        />
        {warning && <WarningText errorText={warning} />}
      </FormField>
    );
  };

  export default CustomTextField;
