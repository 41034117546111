import React, {FunctionComponent, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'aws-northstar/layouts/Container';
import FormRenderer, { componentTypes, validatorTypes} from 'aws-northstar/components/FormRenderer';
import {createRequest, getAccounts} from "../../data";
import {IAccount, IRequest, ReduxRoot} from "../../interfaces";
import {IReviewer} from "../../interfaces";
import {getAllReviewers} from "../../data";
import CustomTextField from './CustomTextField';

import Flashbar, {FlashbarMessage} from 'aws-northstar/components/Flashbar';
import {useSelector} from "react-redux";

export type FormData = {
  requester?: string;
  request_account?: string;
  request_role?: string;
  request_duration?: string;
  request_justification?: string;
  request_emergency?: string;
}

const RequestForm: FunctionComponent = () => {

  const userInfo = useSelector( (state:ReduxRoot) => {
    return state.breakGlassReducerState.userInfo
  });

  const [justification, setJustification] = React.useState("");
  const [accounts, setAccounts] = React.useState([{ label: "", value: "" }]);
  const [reviewers, setReviewers] = React.useState([{ label: "", value: "" }]);
  const [account, setAccount] = React.useState({ label: "", value: "" });
  const [role, setRole] = React.useState({ label: "", value: "" });
  const [approvers, setApprover] = React.useState({ label: "", value: "" });
  // const [autoApprove, setAutoApprove] = React.useState(true);
  const [duration, setDuration] = React.useState("60");
  const [errors, setErrors] = React.useState<FlashbarMessage[]>([]);
  const [submitting, setSubmitting] = React.useState(false);

  const formSchema = {
    header: 'PAM request form',
    description: 'Raise PAM request here',
    fields: [
      {
        component: componentTypes.TEXTAREA,
        name: 'justificationField',
        label: 'Justification for requesting previleged access',
        helperText: 'Maximum 1000 characters',
        initialValue: justification,
        isRequired: true,
        validate: [
          {
              type: validatorTypes.REQUIRED,
          },
        ]
      },
      {
        component: componentTypes.SELECT,
        name: 'accountField',
        label: 'Account requesting access for',
        placeholder: 'Choose the account',
        helperText: 'Enter the AWS Account ID you are requesting access to',
        options: accounts,
        isRequired: true,
        isSearchable: true,
        isClearable: true,
        selectedOption: {account},
        validate: [
          {
              type: validatorTypes.REQUIRED,
          },
        ]
      },
      {
        component: componentTypes.SELECT,
        name: 'roleField',
        label: 'Role requesting access to',
        placeholder: 'Choose the role',
        helperText: 'Select from roles you have access to',
        options: get_roles_values(),
        initialValue: role,
        isRequired: true,
        isSearchable: true,
        isClearable: true,
        selectedOption: {role},
        validate: [
          {
              type: validatorTypes.REQUIRED,
          },
        ]
      },
      {
        component: componentTypes.CUSTOM,
        CustomComponent: CustomTextField,
        useWarnings: true,
        showError: true,
        name: 'durationField',
        label: 'Requested duration for elevated access in minutes',
        type: 'number',
        helperText: 'Maximum 720 minutes (12 hours)',
        isRequired: true,
        initialValue: duration,
        validate: [
          {
              type: validatorTypes.REQUIRED,
          },
          {
            type: validatorTypes.MIN_NUMBER_VALUE,
            includeThreshold: true,
            value: 1,
            message: 'Please enter a duration greater than atleast a minute',
          },
          {
            type: validatorTypes.MAX_NUMBER_VALUE,
            includeThreshold: true,
            value: 720,
            message: 'Please enter a duration less than 720 minutes (12 hours)',
          },
          {
            type: validatorTypes.MAX_NUMBER_VALUE,
            includeThreshold: true,
            warning: true,
            value: 120,
            message: 'Do not request for a duration longer than necessary for your task.'
          },
        ]
      },
      {
        component: componentTypes.SELECT,
        name: 'approversField',
        label: 'Assign reviewers for this request',
        placeholder: 'select the reviewer',
        helperText: 'Select the list of reviewer for this request',
        multiSelect: true,
        options: reviewers,
        checkboxes: true,
        isClearable: true,
        selectedOption: {approvers},
      }
      // {
      //   component: componentTypes.SWITCH,
      //   name: 'autoApprove',
      //   label: 'Auto Approve',
      //   initialValue: autoApprove,
      //   isRequired: true,
      //   helperText: 'When auto approve selected, reviewers selected will be ignored.',
      //   validate: [
      //     {
      //         type: validatorTypes.REQUIRED,
      //     }
      //   ]
      // }
    ]
  };

  const history = useHistory();

  const onSubmit = async (data:any) => {
    setAccount({ label:data.accountField.label, value: data.accountField.value});
    setRole({ label:data.roleField.label, value: data.roleField.value});
    setApprover({ label:data.approversField.label, value: data.approversField.value});
    // setAutoApprove(data.autoApprove);
    setJustification(data.justificationField);
    setDuration(data.durationField);

    try {
      setSubmitting(true);
      await createRequest(userInfo.token, data.accountField, data.roleField.value, data.durationField, data.justificationField, false, data.approversField).then(
          (result: IRequest[]) => {
            return result;
          });
      history.goBack()
      history.push('/Request-dashboard');
    }
    catch (err) {
      const items:FlashbarMessage[] = [
          {
            header: 'Could not create the request',
            content: err.toString(),
            type: 'error',
            dismissible: true,
          }
      ];
      setErrors(items);
      setSubmitting(false);
    }
  }

  useEffect( () => {
    const getAllAccounts = async () => {
      try {
        let accounts = await getAccounts(userInfo.token).then(
          (result: IAccount[]) => {
            return result;
          });

          await Promise.resolve();

          let list: Array<any> = [];
          for (var account of accounts) {
            list.push({label: `${account.name} (${account.id})`, value: account.id});
          }
          setAccounts(list);
      }catch(err){
        const items:FlashbarMessage[] = [
          {
            header: 'Could not get the accounts: ' + err.toString(),
            type: 'error',
            dismissible: true,
          }
        ];
        setErrors(items);
      }
    }
    const getAllReviewersAPI = async () => {
      try {
        let reviewers = await getAllReviewers(userInfo.token).then(
          (result: IReviewer[]) => {
              return result;
          });

          await Promise.resolve();

          let list: Array<any> = [];
          for (var reviewer of reviewers) {
            // if(reviewer.username === userInfo.user.split('@')[0]){
            //   continue;
            // }
            list.push({label: `${reviewer.first_name} ${reviewer.family_name}`, value: reviewer.username});
          }
          setReviewers(list);
      }catch(err){
        const items:FlashbarMessage[] = [
          {
            header: 'Could not get the reviewers: ' + err.toString(),
            type: 'error',
            dismissible: true,
          }
        ];
        setErrors(items);
      }
    }
    getAllReviewersAPI().then(() => console.log("getReviewers() completed."));
    getAllAccounts().then(() => console.log("getAllAccounts() completed."));
  }, [userInfo]);

  return  <Container>
    <Flashbar items={errors} />
    <FormRenderer schema={formSchema} onSubmit={onSubmit} isSubmitting={submitting} onCancel={() => history.goBack()} />
  </Container>
}

function get_roles_values() {
  let list: Array<any> = [];
  list.push({label: "PAM-PowerUser", value: "PAM-PowerUser"});
  list.push({label: "PAM-Admin", value: "PAM-Admin"});
  return list;
}

export default RequestForm;
