import React, {FunctionComponent, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'aws-northstar/components/Button';
import Inline from 'aws-northstar/layouts/Inline';
import StatusIndicator from 'aws-northstar/components/StatusIndicator';
import Popover from 'aws-northstar/components/Popover';
import ColumnLayout, {Column as CLColumn} from 'aws-northstar/layouts/ColumnLayout';
import KeyValuePair from 'aws-northstar/components/KeyValuePair';
import Table, { Column } from 'aws-northstar/components/Table';
import {deleteRequest, getRequests, invokeFederateConsole, invokeFederateCli} from "../../data";
import {ICredential, IRequest, ReduxRoot} from "../../interfaces";
import '../home/styles.css';
import Clipboard from '@react-native-clipboard/clipboard';
import moment from 'moment';

import {
  ExpandableSection,
  Modal,
  Stack,
} from "aws-northstar";
import Flashbar, {FlashbarMessage} from "aws-northstar/components/Flashbar";
import {useSelector} from "react-redux";

const RequestTable: FunctionComponent = () => {

  const userInfo = useSelector( (state:ReduxRoot) => {
    return state.breakGlassReducerState.userInfo
  });

  const columnDefinitions: Column<IRequest>[]= [
    {
      id: 'id',
      width: 300,
      Header: 'Id',
      Cell: ({row} : any) => {
       return <Stack>
                  <Popover
                      position="right"
                      size="large"
                      showDismissButton
                      dismissAriaLabel="Close"
                      // fixedWidth={true}
                      header="PAM Request"
                      triggerType="text"
                      content={
                          <ColumnLayout>
                              <CLColumn key="CLColumn2">
                                  <Stack>
                                      <KeyValuePair label="Id" value={row.original.id}></KeyValuePair>
                                      <KeyValuePair label="Account" value={row.original.request_account}></KeyValuePair>
                                      <KeyValuePair label="Role" value={row.original.request_role}></KeyValuePair>
                                      
                                  </Stack>
                              </CLColumn>
                              <CLColumn key="CLColumn3">
                                  <Stack>
                                      <KeyValuePair label="Duration" value={row.original.request_duration}></KeyValuePair>
                                      <KeyValuePair label="Requested Time" value={row.original.request_time}></KeyValuePair>
                                      <KeyValuePair label="End Time" value={row.original.expiration_time}></KeyValuePair>
                                  </Stack>
                              </CLColumn>
                              <CLColumn key="CLColumn4">
                                  <Stack>
                                       {(function(){
                                          if ( row.original.reviewer ){
                                            return <KeyValuePair label="Reviewer" value={row.original.reviewer}></KeyValuePair>
                                          }else{
                                            return "";
                                          }
                                        })()}
                                      <KeyValuePair label="Request Justification" value={row.original.request_justification}></KeyValuePair>
                                      {(function(){
                                          if ( row.original.reject_message ){
                                            return <KeyValuePair label="Reject Reason" value={row.original.reject_message}></KeyValuePair>
                                          }else{
                                            return "";
                                          }
                                        })()}
                                  </Stack>
                              </CLColumn>
                          </ColumnLayout>
                      }
                    >
                      {row.original.id}
                  </Popover>
              </Stack>
      }
    },
    {
      id: 'request_account',
      width: 120,
      Header: 'Account',
      accessor: 'request_account'
    },
    {
      id: 'request_role',
      width: 120,
      Header: 'Role',
      accessor: 'request_role'
    },
    {
      id: 'request_duration',
      width: 100,
      Header: 'Duration',
      accessor: 'request_duration'
    },
    {
      id: 'request_status',
      width: 100,
      Header: 'Status',
      Cell: ({ row }: any) => {
        if (row && row.original) {
          const status = row.original.request_status;
          switch(status) {
            case 'Requested':
              return <StatusIndicator  statusType='info'>Requested</StatusIndicator>;
            case 'Approved':
              return <StatusIndicator  statusType='positive'>Approved</StatusIndicator>;
            case 'Rejected':
              return <StatusIndicator  statusType='negative'>Rejected</StatusIndicator>;
            case 'Expired':
              return <StatusIndicator statusType='warning'>Expired</StatusIndicator>;
            case 'Ended':
              return <StatusIndicator statusType='warning'>Ended</StatusIndicator>;
            default:
              return <div></div>;
          }
        }

        return row.id;
      }
    },
    {
      id: 'request_time',
      width: 200,
      Header: 'Requested time',
      accessor: 'request_time'
    },
    {
      id: 'expiration_time',
      width: 200,
      Header: 'End time',
      accessor: 'expiration_time'
    },
    {
      id: 'request_url',
      width: 300,
      Header: 'Access',
      Cell: ({ row }: any) => {
        if (row && row.original) {
          const status = row.original.request_status;
          switch(status) {
            case 'Approved':
              return <div>
                <Button loading={accessConsoleloading} onClick={(e) => invokeFederateEndpointConsole(row.original.request_account?row.original.request_account:"", row.original.request_role?row.original.request_role:"")} variant="primary">Access console</Button>
                &nbsp;&nbsp;&nbsp;
                <Button loading={accessCLIloading} onClick={(e) => invokeFederateEndpointCli(row.original.request_account?row.original.request_account:"", row.original.request_role?row.original.request_role:"")} variant="primary">  CLI  </Button>
              </div>
            default:
              return <div></div>;
          }
        }

        return row.id;
      }
    }
  ];

  const [requests, setRequests] = useState<IRequest[]>([]);
  const [selectedItems, setSelectedItems] = useState<IRequest[]>([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [cliVisible, setCliVisible] = useState(false);
  const [cli, setCli] = useState<ICredential>({accessKeyId: "", secretAccessKey: "", sessionToken: ""});
  const [errors, setErrors] = React.useState<FlashbarMessage[]>([]);
  const [accessConsoleloading, setAccessConsoleLoading] = useState(false);
  const [accessCLIloading, setAccessCLILoading] = useState(false);
  const [deleteRequestloading, setDeleteRequestloading] = useState(false);

  const CLIViewer = () => {

    const copyJSONToClipboard = () => {
      let content = getJSON(cli)
      Clipboard.setString(content)
    }

    const copyBashToClipboard = () => {
      let content = getBash(cli)
      Clipboard.setString(content)
    }

    const copyFishToClipboard = () => {
      let content = getFish(cli)
      Clipboard.setString(content)
    }

    const copyPowershellToClipboard = () => {
      let content = getPowershell(cli)
      Clipboard.setString(content)
    }

    const copyWindowsToClipboard = () => {
      let content = getWindows(cli)
      Clipboard.setString(content)
    }

    return (
      <div>
        <Modal title="CLI Commands" visible={cliVisible} onClose={() => setCliVisible(false)}>
          <ExpandableSection variant="borderless" header="JSON">
            <Stack>
              <div>
                <Button onClick={copyJSONToClipboard} variant="primary">Copy JSON</Button>
              </div>
              <div>
                <p>
                  <div className="border_black back_ground_antique_white" >
                    &#123;<br/>
                    &nbsp;&nbsp;&nbsp;&quot;accessKeyId&quot;:&#32;&quot;{cli.accessKeyId}&quot;,<br/>
                    &nbsp;&nbsp;&nbsp;&quot;secretAccessKey&quot;:&#32;&quot;{cli.secretAccessKey}&quot;,<br/>
                    &nbsp;&nbsp;&nbsp;&quot;sessionToken&quot;:&#32;&quot;{cli.sessionToken}&quot;<br/>
                    &#125;
                  </div>
                </p>
              </div>
            </Stack>
          </ExpandableSection>
          <ExpandableSection variant="borderless" header="bash/zsh">
            <Stack>
              <div>
                <Button onClick={copyBashToClipboard} variant="primary">Copy bash/zsh</Button>
              </div>
              <div>
                <p>
                  <div className="border_black back_ground_antique_white" >
                    export AWS_ACCESS_KEY_ID={cli.accessKeyId}<br/>
                    export AWS_SECRET_ACCESS_KEY={cli.secretAccessKey}<br/>
                    export AWS_SESSION_TOKEN={cli.sessionToken}
                  </div>
                </p>
              </div>
            </Stack>
          </ExpandableSection>
          <ExpandableSection variant="borderless" header="fish">
            <Stack>
              <div>
                <Button onClick={copyFishToClipboard} variant="primary">Copy fish</Button>
              </div>
              <div>
                <p>
                  <div className="border_black back_ground_antique_white" >
                    set -x AWS_ACCESS_KEY_ID &quot;{cli.accessKeyId}&quot;<br/>
                    set -x AWS_SECRET_ACCESS_KEY &quot;{cli.secretAccessKey}&quot;<br/>
                    set -x AWS_SESSION_TOKEN &quot;{cli.sessionToken}&quot;
                  </div>
                </p>
              </div>
            </Stack>
          </ExpandableSection>
          <ExpandableSection variant="borderless" header="Powershell">
            <Stack>
              <div>
                <Button onClick={copyPowershellToClipboard} variant="primary">Copy Powershell</Button>
              </div>
              <div>
                <p>
                  <div className="border_black back_ground_antique_white" >
                    $Env:AWS_ACCESS_KEY_ID=&quot;{cli.accessKeyId}&quot;<br/>
                    $Env:AWS_SECRET_ACCESS_KEY=&quot;{cli.secretAccessKey}&quot;<br/>
                    $Env:AWS_SESSION_TOKEN=&quot;{cli.sessionToken}&quot;
                  </div>
                </p>
              </div>
            </Stack>
          </ExpandableSection>
          <ExpandableSection variant="borderless" header="Windows cmd">
            <Stack>
              <div>
                <Button onClick={copyWindowsToClipboard} variant="primary">Copy Windows cmd</Button>
              </div>
              <div>
                <p>
                  <div className="border_black back_ground_antique_white" >
                    set AWS_ACCESS_KEY_ID={cli.accessKeyId}<br/>
                    set AWS_SECRET_ACCESS_KEY={cli.secretAccessKey}<br/>
                    set AWS_SESSION_TOKEN={cli.sessionToken}
                  </div>
                </p>
              </div>
            </Stack>
          </ExpandableSection>
        </Modal>
      </div>
    );
  };

  const invokeFederateEndpointConsole = async (account: string, role: string) => {
    try {
      setAccessConsoleLoading(true);
      let response = await invokeFederateConsole(userInfo.token, account, role).then(
        (result: any) => {
          return result;
        });

      window.open(response, "")
    }
    catch (err) {
      const items:FlashbarMessage[] = [
        {
          header: 'Could not federate into the account and role: ' + err.toString(),
          type: 'error',
          dismissible: true,
        }
      ];
      setErrors(items);
    }
    setAccessConsoleLoading(false);
  }

  const invokeFederateEndpointCli = async (account: string, role: string) => {
    try {
      setAccessCLILoading(true);
      let response = await invokeFederateCli(userInfo.token, account, role).then(
        (result: any) => {
          return result;
        });

      setCli(response);
      setCliVisible(true);
    }
    catch (err) {
      console.log(err.toString());
      const items:FlashbarMessage[] = [
        {
          header: 'Could not get the details to federate into the account: ' + err.toString(),
          type: 'error',
          dismissible: true,
        }
      ];
      setErrors(items);
    }
    setAccessCLILoading(false);
  }

  useEffect( () => {
    const getAllRequests = async () => {

      try {
        setLoading(true);
        let requests = await getRequests(userInfo.token).then(
          (result: IRequest[]) => {
            return result;
          }
        );

        const modifiedRequests = requests.map((request) => {
          let localReqTime = '';
          let localExpTime = '';
          if (request.request_time) {
            localReqTime = moment.utc(request.request_time, "MM/DD/YY HH:mm:ss").local().format();
          }
          if (request.expiration_time) {
            localExpTime = moment.utc(request.expiration_time, "MM/DD/YY HH:mm:ss").local().format();
          }
          return{
            ...request,
            request_time: localReqTime,
            expiration_time: localExpTime
          }
        });

        setRequests(modifiedRequests);

        await Promise.resolve();

        setLoading(false);
      }
      catch (err) {
        const items:FlashbarMessage[] = [
          {
            header: 'Could not get the requests: ' + err.toString(),
            type: 'error',
            dismissible: true,
          }
        ];
        setErrors(items);
      }
    }
    getAllRequests().then(() => console.log("getAllRequests() completed."));
  }, [userInfo]);

  const onCreateClick = () => {
    history.push('/Create-request');
  }

  const onDeleteClick = async () => {
    let selectedItem:IRequest = selectedItems[0];
    try {
      setDeleteRequestloading(true);
      var utcReqTime = moment.utc(moment(selectedItem.request_time)).format("MM/DD/YY HH:mm:ss");
      await deleteRequest(userInfo.token, selectedItem.id, utcReqTime).then(
        (result: any) => {
          return result;
        });

      let selected_requests = remove_request(requests, selectedItem)
      setRequests(selected_requests);
      history.push('/Request-dashboard');
    }
    catch (err) {
      const items:FlashbarMessage[] = [
        {
          header: 'Could not delete the request',
          content: err.toString(),
          type: 'error',
          dismissible: true
        }
      ];
      setErrors(items);
    }
    setDeleteRequestloading(false);
  }

  const handleSelectionChange = (items: object[]) => {
    if (!(selectedItems.length === 0 && items.length === 0)) {
      setSelectedItems(items);
    }
  };

  const tableActions = (
    <Inline>
      <Button onClick={onCreateClick} variant="primary">
        Create request
      </Button>
      <Button loading={deleteRequestloading} disabled={!(selectedItems.length === 1 && (selectedItems[0].request_status === 'Requested' || selectedItems[0].request_status === 'Expired'))} onClick={onDeleteClick}>
        Delete request
      </Button>
    </Inline>
  );

  return <div>
    <Flashbar items={errors} />

    <Table
      onSelectionChange={handleSelectionChange}
      tableTitle={'Requests you have submitted for review'}
      columnDefinitions={columnDefinitions}
      loading={loading}
      items={requests}
      actionGroup={tableActions}
      multiSelect={false}
      disableSettings={false}
      disableSortBy={false}
      defaultPageSize={25}
      pageSizes={[25, 50, 75, 100]}
      sortBy={[
        {
          id: 'request_time',
          desc: true
        }
      ]}
    />
    <CLIViewer />
  </div>
}

function getJSON(credential: ICredential) {
  let output: string = "";
  output = output.concat("{\n")
  output = output.concat("\t\"accessKeyId\": \"")
  output = output.concat(credential.accessKeyId)
  output = output.concat("\",\n")
  output = output.concat("\t\"secretAccessKey\": \"")
  output = output.concat(credential.secretAccessKey)
  output = output.concat("\",\n")
  output = output.concat("\t\"sessionToken\": \"")
  output = output.concat(credential.sessionToken)
  output = output.concat("\"\n}")
  return output;
}

function getBash(credential: ICredential) {

  let output: string = "";
  output = output.concat("export AWS_ACCESS_KEY_ID=")
  output = output.concat(credential.accessKeyId)
  output = output.concat("\n")
  output = output.concat("export AWS_SECRET_ACCESS_KEY=")
  output = output.concat(credential.secretAccessKey)
  output = output.concat("\n")
  output = output.concat("export AWS_SESSION_TOKEN=")
  output = output.concat(credential.sessionToken)
  return output;
}

function getFish(credential: ICredential) {

  let output: string = "";
  output = output.concat("set -x AWS_ACCESS_KEY_ID \"")
  output = output.concat(credential.accessKeyId)
  output = output.concat("\"\n")
  output = output.concat("set -x AWS_SECRET_ACCESS_KEY \"")
  output = output.concat(credential.secretAccessKey)
  output = output.concat("\"\n")
  output = output.concat("set -x AWS_SESSION_TOKEN \"")
  output = output.concat(credential.sessionToken)
  output = output.concat("\"")
  return output;
}

function getPowershell(credential: ICredential) {

  let output: string = "";
  output = output.concat("$Env:AWS_ACCESS_KEY_ID=\"")
  output = output.concat(credential.accessKeyId)
  output = output.concat("\"\n")
  output = output.concat("$Env:AWS_SECRET_ACCESS_KEY=\"")
  output = output.concat(credential.secretAccessKey)
  output = output.concat("\"\n")
  output = output.concat("$Env:AWS_SESSION_TOKEN=\"")
  output = output.concat(credential.sessionToken)
  output = output.concat("\"")
  return output;
}

function getWindows(credential: ICredential) {

  let output: string = "";
  output = output.concat("set AWS_ACCESS_KEY_ID=")
  output = output.concat(credential.accessKeyId)
  output = output.concat("\n")
  output = output.concat("set AWS_SECRET_ACCESS_KEY=")
  output = output.concat(credential.secretAccessKey)
  output = output.concat("\n")
  output = output.concat("set AWS_SESSION_TOKEN=")
  output = output.concat(credential.sessionToken)
  return output;
}

function remove_request(requests: any, selected: any) {
  let list: Array<any> = [];
  for (var request of requests) {
    if (request.id !== selected.id) {
      list.push(request)
    }
  }
  return list;
}

export default RequestTable;
